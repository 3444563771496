.title-14 {
    font-size: 14px;
    color: #004159;
}

.MuiCircularProgress-root svg {
    color: rgb(255, 136, 68);
}

.MuiLinearProgress-root {
    height: 10px !important;
    background-color: #00415930 !important;
}

.MuiLinearProgress-root span {
    background-color: rgb(255, 136, 68) !important;
}

.login-window {
    width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
}

.login-window .tube-btn {
    text-align: center;
    padding: 10px;
    font-size: 16px;
}

.login-window .incorrect-password {
    color: #8b1919;
    text-align: center;
    margin-top: 10px;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #004159 transparent;
  }

.bold {
    font-weight: 600;
}

.capitalize {
    text-transform: capitalize;
}

.tube-status-online::before,
.tube-status-offline::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #66ffbf;
    position: relative;
    display: inline-block;
    border-radius: 10px;
    margin-top: 5px;
    left: -5px;
}

.tube-status-offline::before {
    background-color: #ffafb1;
}

.Mui-focused.MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.87) !important;
}