body {
  margin: 0;
  color: #004159;
  line-height: 1.3rem;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, div {
  font-family: "Outfit",sans-serif;
}

.r90 {
  transform: rotate(90deg);
}