.dashboard-widget {
    width: calc(50% - 10px);
    background-color: #f2f4f8;    
    padding: 17px 24px;
    border-radius: 17px;
    font-size: 14px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.dashboard-widget-header {
    color: #004159;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dashboard-widget-icon {
    margin-right: 5px;
}
.dashboard-widget-row {
    display: flex;
    margin-top: 3px;
    font-size: 14px;
}

.dashboard-widget-row-link:hover {
    cursor: pointer;
    font-weight: 600;
}
.dashboard-widget-value-name {
    flex: 1
}
.dashboard-widget-value {
    background-color: rgba(0, 65, 89, .1);
    border-radius: 20px;
    align-items: center;
    padding-left: 9px;
    padding-right: 9px;
}

.dashboard-widget-wide {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashboard-widget-wide svg {
    margin-right: 10px;
}

.dashboard-rc {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: right;
    font-size: 14px;
    font-weight: 300;
}

.dashboard-rc div:not(:last-child) {
    margin-right: 10px;
}

.dashboard-bold {
    font-weight: 600;
    margin-left: 5px;
}

.dashboard-widget .confirm-button {
    top: 0;
}

.dashboard-widget-title {
    flex: 1;
}

.dashboard-widget.dashboard-widget-red {
    background-color: #ffeee5;
}

.dashboard-subheader {
    display: flex;
}

.dashboard-subheader div:first-child {
    font-size: 16px;
    font-weight: 500;
    color: #ff6400;
}

.dashboard-tag-green,
.dashboard-tag-orange {
    background-color: #66ffbf;
    border-radius: 20px;
    align-items: center;
    margin-left: 10px;
    padding-left: 9px;
    padding-right: 9px;
}

.dashboard-tag-orange {
    color: #fff;
    background-color: #ff6400;
    margin-left: 0px;
}

.dashboard-stats {
    display: flex;
}

.dashboard-stats > div {
    margin-right: 15px;
}

.dashboard-stats > div > div:first-child {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    padding-top: 10px;
}

.dashboard .line {
    background-color: rgba(170, 187, 204, .5);
    width: 100%;
    height: 1px;
    margin: 10px 0;
}

.dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dashboard-widget-notifications {
    background-color: #fff;
    border: 1px solid rgba(170, 187, 204, .6)
}

.dashboard-widget-notifications .dashboard-widget-header {
    color: #ff6400;
}

.dashboard .confirm-button {
    border-color: #abc !important;
}

.dashboard-list {
    width: calc((100% - 40px) / 3);
    margin-top: 20px;
}

.dashboard-list .dashboard-rc div:not(:last-child) {
    margin-right: 5px;
}

.dashboard-video-thumbnail {
    width: 150px;
    height: 85px;
    background-size: cover;
    background-position: 50%;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
}

.dashboard-video-thumbnail svg {
    background-color: rgba(200, 0, 0, .7);
    width: 24px;
    height: 24px;
    color: #fff;
    border-radius: 100%;
    padding: 5px;
    position: absolute;
    right: 5px;
    top: 5px;
}

.dashboard-video-row {
    display: flex;
    font-size: 14px;
    align-items: center;
    padding-left: 10px;
}

.dashboard-video-row > div:nth-child(2) {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
}

.dashboard-video-row > div:nth-child(3) {
    padding-right: 10px;
}

.dashboard-video-title {
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.dashboard-video-created {
    font-size: 12px;
}

.dashboard-video:hover {
    background-color: #f2f4f8;
}

.dashboard-list .line {
    margin-bottom: 0;
}

.dashboard-list > .line:nth-child(2) {
    margin-bottom: 10px;
}

.dashboard-video {
    padding-top: 10px;
}

.dashboard-video:hover video {
    display: block !important;
    object-fit: cover;
}

.dashboard-actions {
    margin-top: 60px;
}