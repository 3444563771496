.tube-drag-and-drop-item {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    padding: 10px;
    background-color: #f2f4f8;
    border-radius: 10px;
    margin-bottom: 5px;
}

.tube-drag-and-drop-item > div {
    width: 100%
}

.tube-drag-and-drop-item .select-input {
    height: 40px;
}

.tube-drag-and-drop-item .tube-btn,
.tube-drag-and-drop-item .button-switch-label,
.tube-drag-and-drop-item .text-input-wrapper {
    margin-bottom: 0px !important;
}

.tube-drag-and-drop-item .drag-icon {
    cursor: move;
}