.text-input .MuiOutlinedInput-root {
    background-color: #fff;
}

.text-input input {
    border-radius: 7px;
    margin-bottom: 0;
    padding: 12px 11px;
    color: #333;
    font-weight: 300;
    font-size: 14px
}

.text-input fieldset {
    border-color: #abc
}

.text-input label {
    color: #004159 !important;
    line-height: 16px;
    font-size: 14px;
    margin-top: -2px;
}

.text-input label[data-shrink="true"] {
    line-height: 24px;
    font-size: 16px;
}

.text-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #004159 !important;
    border-width: 1px !important;
}

.text-input-wrapper {
    margin-bottom: 7px !important;
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.text-input-description {
    padding-left: 100px;
    width: 400px;
    font-size: 12px;
}

.text-input .MuiFormHelperText-root {
    color: #004159
}