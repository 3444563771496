.tube-btn:hover:not(.tube-btn-active) {
    background-color: rgba(170, 187, 204, .4);
}

.tube-btn {
    color: #004159;
    cursor: pointer;
    background-color: #f2f4f8;
    border: 1px solid #abc;
    border-radius: 7px;
    align-items: center;
    margin-bottom: 4px;
    margin-right: 4px;
    padding: 5px 7px;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
    transition: all .2s;
    display: inline-block;
    font-size: 14px;
}

.tube-btn-active {
    background-color: #004159;
    border: 1px solid #004159;
    color: #fff;
}

.tube-btn-image img {
    width: 120px;
}

.tube-btn-image img {
    width: 120px;
    width: 120px;
    height: 28px;
    margin-bottom: 10px;
}

.tube-btn-image {
    background-color: #FFF;
    color: #004159;
    display: inline-flex;
    flex-direction: column;
    padding: 15px;
    font-size: 16px;
    border-radius: 14px;
    border: 1px solid #abc;
}

.tube-btn-active.tube-btn-image, .tube-btn-image:hover {
    border: 1px solid #ff6400;
}

.tube-btn-active.button-switch-color-green {
    background-color: #66ffbf;
    border-color: #66ffbf;
    color: #004159;
}

.tube-btn-active.button-switch-color-red {
    background-color: #ffafb1;
    border-color: #ffafb1;
    color: #c80000;
}