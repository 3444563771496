.select-input {
    margin-bottom: 7px !important;
    background-color: #fff;
}

.select-input .MuiSelect-select {
    border-radius: 7px;
    margin-bottom: 0;
    padding: 10px 11px;
    color: #333;
    font-weight: 300;
    font-size: 14px
}

.select-input > fieldset {
    border-color: #abc;
}

.select-input .tube-chip {
    background-color: #abc;
}

.select-input label {
    color: #004159 !important;
    line-height: 16px;
    font-size: 14px;
    margin-top: -2px;
}

.select-input label[data-shrink="true"] {
    line-height: 24px;
    font-size: 16px;
}

.select-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #004159 !important;
    border-width: 1px !important;
}