.MuiSlider-thumb {
    background-color: #FFF !important;
    border: 1px solid #abc !important;
    width: 26px !important;
    height: 26px !important;
}
.MuiSlider-thumb::before {
    box-shadow: none !important;  
} 

.MuiSlider-thumb:hover {
    border: 1px solid #ff6400 !important;
    box-shadow: none !important;  
}

.MuiSlider-rail {
    background-color: #abc !important;
    opacity: 1 !important;
}

.MuiSlider-root {
    height: 2px !important;
    color: #ff6400 !important;
}

.slider-range {
    text-align: center;
    font-size: 12px;
    margin-top: -5px;
}