.dialog-form-buttons-stack {
    margin-top: -56px;
    right: 23px;
    border-top: 1px solid #abc;
    width: 100%;
    justify-content: flex-end;
    padding-top: 10px !important;
}

.dialog-form-buttons-stack label {
    float: right
}

.max-1000 .MuiPaper-root {
    max-width: 1000px;
}

.MuiDialogContent-root {
    overflow: visible !important;
}

.MuiDialogContent-root .form-submit {
    position: relative;
    top: -7px;
}

.MuiDialog-paper {
    border-radius: 20px !important;
    background: #f2f4f8 !important;
    overflow: auto;
}

.MuiDialog-paper pre {
    overflow: scroll;
}