.text-area {
    width: 100%;
    margin-bottom: 7px !important;
}

.text-area textarea {
    border-radius: 7px;
    margin-bottom: 0;
    padding: 12px 11px;
    color: #333;
    font-weight: 300;
    font-size: 14px;
    resize: vertical;
    background-color: #FFF;
}

.text-area fieldset {
    border-color: #abc
}

.text-area label {
    color: #004159 !important;
    line-height: 16px;
    font-size: 14px;
    margin-top: -2px;
}

.text-area label[data-shrink="true"] {
    line-height: 24px;
    font-size: 16px;
}

.text-area .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #004159 !important;
    border-width: 1px !important;
}

.text-area .MuiOutlinedInput-root {
    padding: 0;
}

.text-area-wrapper {
    margin-bottom: 7px !important;
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.text-area-description {
    padding-left: 100px;
    width: 400px;
    font-size: 12px;
}