.video-preview {
    width: 100%;
    position: relative;
}
.video-card {
    width: 100%;
    padding: 17px 24px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 15px;
}

.video-card .MuiAvatar-root {
    background-color: #abc;
    width: 34px;
    height: 34px;
    font-size: 14px
}

.video-card-user-name {
    font-weight: 600;
    font-size: 14px;
    color: #004159;
    margin-top: -2px;
}

.video-card-user-registration {
    font-size: 12px;
    color: #004159;
    margin-top: -5px;
}

.video-card-arrow.MuiButtonBase-root {
    background-color: #fff;
    color: #004159;
    font-size: 16px;
    float: right;
}

.video-card-arrow.MuiButtonBase-root:hover {
    color: #fff;
    background-color: #abc;
}

.video-card-line div {
    height: 1px;
    width: 100%;
    background-color: #abc;
}

.video-card-data-name {
    font-size: 14px;
    color: #004159;
}

.video-card-data-value {
    background-color: rgba(0, 65, 89, .1);
    border-radius: 20px;
    padding-left: 9px;
    padding-right: 9px;
    line-height: 1.2rem;
    font-size: 14px;
    color: #004159;
    float: right;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.video-card-line {
    padding: 12px 0;
}

.video-preview-thumbnail {
    width: 150px;
    border-radius: 10px;
    background-color: #fff;
    background-size: cover;
    height: 85px;
    object-fit: cover;
    border: 1px solid #abc;
}

div.video-preview-thumbnail {
    width: 150px;
    border-radius: 10px;
    height: 85px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #abc;
    font-weight: 600;
    font-size: 12px;
}

.video-preview .video-file {
    width: calc(50vw - 60px);
    height: calc((50vw - 60px) / 16 * 9);
    border-radius: 10px;
}

.video-card-data-value a {
    white-space: nowrap;
    color:#004159;
}