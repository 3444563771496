.tube-chip {
    color: #fff;
    cursor: pointer;
    background-color: #abc;
    border-radius: 4px;
    align-items: center;
    margin: 2px;
    padding: 0px 7px;
    text-decoration: none;
    transition: all .2s;
    display: flex;
}

.tube-chip:hover {
    background-color: #004159;
}

.tube-chip-temp::after {
    content: "x";
    font-size: 18px;
    font-weight: 300;
    position: relative;
    top: -2px;
    margin-left: 7px;
}