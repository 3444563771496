.input-border::after {
    content: "";
    height: 1px;
    width: calc(100% - 9px);
    background-color: #abc;
    display: block;
    margin-top: 7px;
    margin-bottom: 20px;
    position: absolute;
}

.input-border {
    padding-bottom: 20px;
    position: relative;
}

.MuiGrid-grid-md-6:nth-child(2n) {
    padding-left: 15px !important;
}

.MuiGrid-grid-md-6:nth-child(2n + 1) {
    padding-right: 15px !important;
}

.tube-gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-top: 15px;
}

.tube-gallery-item {
    flex: 0 0 calc((100% - 45px) / 4);
    margin-right: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
    position: relative;
}

.tube-gallery-item:nth-child(4n) {
    margin-right: 0px;
}

.internal-error.text-input,
.internal-error.text-area textarea,
.internal-error.tube-multiselect-labels-container {
    background: rgb(250, 225, 220);
}

.internal-error.text-input fieldset,
.internal-error.text-area fieldset,
.internal-error.tube-multiselect-labels-container {
    border-color: rgb(238, 109, 109);
}

.form-error-msg {
    color: rgb(192, 51, 46);
    font-size: 14px;
    font-weight: 600;
}