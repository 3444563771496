.videos-filter .line {
    width: 100%;
    height: 1px;
    background-color: rgba(170, 187, 204, .5);
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 7px;
}

.videos-filter .flex-row {
    display: flex;
    flex-direction: row;
    padding: 10px
}

.videos-filter .date-picker {
    background-color: #FFF;
}