.settings-appearance,
.settings-seo {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.settings-card {
    background-color: #f2f4f8;
    padding: 20px 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.settings-card-row {
    flex-direction: row;
    gap: 20px 30px;
    flex-wrap: wrap;
}

.settings-card-row .MuiStack-root {
    width: calc((100% - 90px) / 4);
}

.settings-card-white {
    background-color: #fff;
    border: 1px solid #abc9;
}

.settings-card .upload-thumbnail {
    background-color: #fff;
    border-radius: 5px;
    border-width: 0px;
}

.settings-card .confirm-button {
    width: 80px;
}

.settings-card .delete-button {
    padding: 0px !important;
    min-width: 50px;
    width: 50px;
    margin-left: 5px !important;
}

.settings-card .upload-label {
    margin-bottom: 10px;
}

.settings-card .tube-upload-description {
    margin-top: 10px;
}

.settings-form-dialog .MuiDialogContent-root > div:first-child {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
    width: 400px;
}

.tube-drag-and-drop-item > div.settings-button-stack {
    width: auto;
    white-space: nowrap;
}

.settings-title-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.settings-title-wrapper .confirm-button {
    width: auto;
    white-space: nowrap;
    margin-top: -12px;
}

.settings-tags .confirm-button {
    margin-top: 12px;
}

.settings-title-wrapper > div {
    width: 100%;
}

.settings .social-icon {
    width: 40px !important;
    height: 40px !important;
}

.settings .add-img {
	background-image: url("https://cdn.prod.website-files.com/63ec1175068777721ffa0c58/63f503104ad4fe05e17a6836_Screenshot%202023-02-21%20at%2017.44.12.png");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
	height: 190px;
	margin-bottom: 20px;
	padding: 11px;
	display: flex;
}

.settings-sub-card {
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
}

.settings-sub-card p {
    font-size: 12px;
    padding: 0 30px;
}

.settings-sub-card-design {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0px 30px;
}

.settings .tube-colorpicker {
    width: calc((100% - 60px) / 3);
}

.settings-tags th.name,
.settings-categories th.name {
    font-weight: 600;
}

.settings-tags th.seo_description,
.settings-tags th.seo_keywords,
.settings-categories th.seo_description,
.settings-categories th.seo_keywords {
    white-space: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
}

.settings-categories .MuiAvatar-root {
    border-radius: 5px;
    width: 80px;
    background-color: #F2F4F8;
}

.settings-categories .MuiAvatar-root path {
    display: none;
}

.settings-categories th.tags,
.settings-categories th.seo_description,
.settings-categories th.seo_keywords {
    min-width: 300px;
}