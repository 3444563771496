.import-source .MuiDialogContent-root {
    width: 700px;
}

.import-status {
    border-radius: 20px;
    color: #004159; 
    margin-right: 10px;
    padding-left: 9px;
    padding-right: 9px;
    text-align: center;
    line-height: 20px
}

.import-status.import-status-finished {
    background-color: #66ffbf;  
}

.import-status.import-status-added {
    background-color: #faf1a0 ;  
}

.import-status.import-status-queue {
    background-color: rgba(0, 65, 89, .1);
}

.grabber_list_btn {
    border-radius: 20px;
    background-color: #FFF;
    margin-right: 10px;
    padding-left: 9px;
    padding-right: 9px;
    text-align: center;
    line-height: 26px;   
    width: 80px;
    cursor:pointer;
}