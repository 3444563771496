.upload-label {
    font-size: 14px;
    margin-bottom: 4px;
}

.upload-thumbnail {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    border: 10px solid #f2f4f8;
    box-sizing: border-box;
    border-radius: 10px;
    background-image: 
        linear-gradient(45deg, #abc 25%, transparent 25%, transparent 75%, #abc 75%, #abc),
        linear-gradient(45deg, #abc 25%, transparent 25%, transparent 75%, #abc 75%, #abc);
    background-size: 25px 25px;
    background-position: 0 0, 12.5px 12.5px;
    position: relative;
}

.MuiDialog-paper .upload-thumbnail {
    background-color: #FFF;
    border-color: #FFF;
}

.text-input-upload {
    margin-right: 10px !important;
}

.MuiStack-root > .tube-upload-description {
    width: 100%;
    font-size: 12px;
    margin: 0px;
}

.upload-thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}