.tube-select-image-label {
    font-size: 14px;
}

.tube-select-image-placeholder {
    border: 3px dashed #abc;
    border-radius: 10px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.tube-select-image-placeholder img {
    width: 100%;
}

.tube-select-image-dialog-content {
    width: 640px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.tube-select-image-item {
    width: 200px;
    cursor: pointer;
}

.tube-select-image-item img {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #abc;
    border-radius: 10px;
    padding: 5px
}

.tube-select-image-item:hover img {
    border: 2px solid #ff6400;
}

.tube-select-image-item-name {
    font-size: 14px;
}