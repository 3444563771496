.multi-text-input {
    margin-bottom: 7px !important;
    background-color: #fff;
}

.multi-text-input .MuiSelect-select {
    border-radius: 7px;
    margin-bottom: 0;
    padding: 10px 11px;
    color: #333;
    font-weight: 300;
    font-size: 14px
}

.multi-text-input > fieldset {
    border-color: #abc;
}

.tube-multiselect-labels-container .tube-chip {
    background-color: #abc;
    font-size: 14px;
    display: inline-block;
    padding: 2px 10px;
    cursor: pointer;
}

.tube-multiselect-labels-container .tube-chip:hover {
    background-color: #004159;
}

.tube-multiselect-labels-container .tube-chip::after {
    content: "x";
    font-size: 18px;
    font-weight: 300;
    position: relative;
    top: -1px;
    margin-left: 7px;
}

.multi-text-input label {
    color: #004159 !important;
    line-height: 16px;
    font-size: 14px;
    margin-top: -2px;
}

.multi-text-input label[data-shrink="true"] {
    line-height: 24px;
    font-size: 16px;
}

.multi-text-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #004159 !important;
    border-width: 1px !important;
}

.multiselect-extra-func-button.confirm-button.outlined {
    margin-left: 10px;
    border-color: #abc !important;
    position: absolute;
    right: 0;
    top: 20px
}

.tube-multiselect-label {
    font-size: 14px;
    margin-bottom: 10px;
}

.tube-multiselect-labels-container {
    border: 1px solid #abc;
    padding: 7.5px 11px;
    min-height: 29px;
    border-radius: 5px;
    background: #FFF;
    margin-bottom: 5px;
}

.tube-multiselect-component {
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 1px solid #abc;
    padding-top: 30px;
    padding-bottom: 10px;
}

.tube-multiselect-list {
    position: absolute;
    margin-top: -3px;
    border: 1px solid #ff6400;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    z-index: 2;
}

.tube-multiselect-list-item, .tube-multiselect-list-searching {
    color: #004159;
    font-size: 14px;
    cursor: pointer;
}

.tube-multiselect-list-item:hover {
    background-color: #f2f4f8;
}