.data-table-header th {
    background-color: #abc;
    color: #fff;
    font-weight: 300;
    padding: 10px
}

.data-table-header th:first-child {
    border-radius: 7px 0px 0px 7px;
}

.data-table-header th:last-child {
    border-radius: 0px 7px 7px 0px;
}

.data-table-header th {
    padding: 10px 0px;
    padding-right: 20px;
    border-bottom: none;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
}

.data-table-row th {
    color: #004159;
    font-family: Outfit, sans-serif;
    font-weight: 300;
    line-height: 1.2rem;
    border-top: 1px solid #abc;
    border-bottom: none;
    padding: 0;
    white-space: nowrap;
    padding-right: 10px;
    text-overflow: ellipsis;
    max-width: 500px;
    overflow: hidden;
}
.data-table-row:last-child th {
    border-top: 1px solid #abc !important;
    border-bottom: 1px solid #abc !important;
}

.data-table-row th:first-child, .data-table-header th:first-child {
    padding: 0 10px;
    width: 24px
}

.data-table-row:hover {
    background-color: #f2f4f8;
}

.white-space-row td {
    padding: 5px;
    border-bottom: none;
}

.bulk-edit {
    background-color: #f2f4f8;
    border: 1px solid #ff6400;    
    border-radius: 7px;
    padding: 27px;
    margin-bottom: 11px;
}
.bulk-edit-item {
    border-top: 1px solid #abc;
    padding: 4px 0;
    font-size: 14px;
    font-weight: 600;
    padding-left: 23px;
    cursor: pointer;
}
.bulk-edit-item::before {
    content: "🗸";
    position: absolute;
    margin-left: -23px;
    font-size: 18px;
    margin-top: 2px;
    color: #1b76d0;
}
.bulk-edit-item:hover {
    color: #ff6400;
}
.bulk-edit-item:last-child {
    border-bottom: 1px solid #abc;
}

.bulk-edit .title-14 {
    margin-bottom: 5px;
}

.bulk-edit-divider {
    width: 100%;
    height: 1px;
    background-color: #abc;
    margin: 10px 0;
}

.large-view .thumbnail {
    width: 150px;
    height: 85px;
    background-size: cover;
    background-position: 50%;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
}

.large-view .thumbnail video {
    position: relative;
    z-index: 2;
    object-fit: cover;
}

.large-view .thumbnail svg{
    background-color: rgba(200, 0, 0, .7);
    width: 24px;
    height: 24px;
    color: #fff;
    border-radius: 100%;
    padding: 5px;
    position: absolute;
    right: 5px;
    top: 5px;
}

.large-view .photoThumbnail {
    width: 78px;
    height: 78px;
    background-color: #f2f4f8;
    border-radius: 7px;
    border-color: #f2f4f8;
    padding: 6px;
    overflow: hidden;
    position: relative;
}

.large-view .photo {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.large-view th {
    padding: 10px !important;
}

.large-view .user-avatar {
    background-color: #abc;
    font-size: 14px;
}

.large-view .user-online {
    background-color: #ffafb1;
    width: 10px;
    height: 10px;
    margin-top: -15px;
    border-radius: 10px;
    border: 2px solid #FFF;
    left: 45px;
    position: relative;
}

.large-view .boldText {
    font-weight: 600;
}

.large-view .text svg {
    display: inline;
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
}

.large-view .text {
    white-space: nowrap;
}

.large-view .smallText {
    font-size: 11px;
}

.page-button {
    width: 30px;
    height: 30px;
    font-size: 14px !important;
    color: #004159 !important;
    border: 1px solid #abc !important; 
    border-radius: 40px !important;
    margin: 20px 1px !important;
}

.page-button.page-button-active {
    background-color: #004159 !important;
    border-color: #004159 !important;
    color: #FFF !important;
}

.data-table .thumbnail.no-preview {
    background-color: #f2f4f8;
    border: 1px solid #abc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.data-table .no-preview-text {
    font-size: 12px;
    font-weight: 600;
    color: #abc;
}

.data-table-blue-text {
    background-color: #abc;
    padding: 0 5px;
    display: inline-block;
    border-radius: 5px;
}

.data-table-green-text {
    background-color: #66ffbf;
    padding: 0 5px;
    display: inline-block;
    border-radius: 5px;
}

.data-table-red-text {
    background-color: #ffafb1;
    padding: 0 5px;
    display: inline-block;
    border-radius: 5px;
}

.data-list-tube-select-image {
    max-width: 100px;
    max-height: 80px;
    margin-top: 5px;
}

