.grabbers-source {
    background-color: #f2f4f8;
    padding: 20px;
    border-radius: 20px    
}

.grabbers-source-header {
    color: #004159;
    font-size: 24px;
    margin-bottom: 10px
}

.grabbers-source #source {
    background-color: #FFF;   
}