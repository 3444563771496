.tube-cover {
    width: 100%;
    border-radius: 20px;
    background: #eee;
    height: 200px;
    background-position: 50%;
    background-size: cover;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.tube-cover-logo {
    width: 140px;
    height: 140px;
    border-radius: 100%;
    background: #ddd;
    margin-left: 20px;
    box-sizing: border-box;
    border: 3px solid #FFF;
    background-position: 50%;
    background-size: cover;
}