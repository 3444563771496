.data-table-row th.title {
    font-weight: 600;
}

.data-table-row th.status {
    padding-left: 10px;
}

.data-table-row th.status::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #66ffbf;
    position: relative;
    display: inline-block;
    border-radius: 10px;
    margin-top: 5px;
    left: -5px;
}

.data-table-row th.status.videos-list-status-offline::before {
    background-color: #ffafb1;
}

.data-table-row th.categories {
    min-width: 300px;
}