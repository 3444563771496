.tube-checkbox {
    background-color: #fff !important;
    color: #fff !important;
    min-width: 0px !important;
    width: 24px;
    height: 24px;
    display: block;
    border-radius: 100% !important;
    padding: 2px 3px !important;
    border: 1px solid #abc !important;
}

.tube-checkbox:hover {
    color: #abc !important;
}

.tube-checkbox-active {
    background-color: #f84 !important;
    border: 1px solid #f84 !important;
}

.tube-checkbox-active:hover {
    color: #fff !important;
}