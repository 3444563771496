.category-line {
    border-top: 1px solid #abc;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.category-line > div:nth-child(3) {
    padding-left: 10px;
    flex: 1;
}

.category-line > div:nth-child(1) {
    padding-right: 10px;
}

.category-line .name {
    font-size: 14px;
    font-weight: 900;
}

.category-line .created_at {
    font-size: 12px;
}