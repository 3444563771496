.confirm-button {
    color: #fff;
    cursor: pointer;
    background-color: #ff6400 !important;
    border: 1px solid #ff6400 !important;
    border-radius: 30px !important;
    align-items: center;
    padding: 4px 20px !important;
    text-decoration: none;
    transition: all .2s;
    display: flex;     
    text-transform: none !important;
    font-weight: 300 !important;
    box-shadow: none !important;
    height: 32px;
    position: relative;
    top: 6px
}

.confirm-button:hover {
    background-color: #c80000 !important;
    border: 1px solid #c80000 !important;
    box-shadow: none !important;
}

.confirm-button.outlined {
    color: #ff6400;
    background-color: #fff !important;
    border: 1px solid #ff6400 !important;
}

.confirm-button.outlined:hover {
    border: 1px solid #c80000 !important;
    color: #c80000;
}

.confirm-button.filter-button {
    background-color: #abc !important;
    border: 1px solid #abc !important;
    float: right
}

.tube-submit-button-icon {
    margin-right: 4px;
}

.confirm-button.no-label {
    margin-left: 0px !important;
    width: 140px    
}
.confirm-button.outlined.upload-button {
    border-color: #abc !important;
    white-space: nowrap;
}

.confirm-button.no-margin-top {
    top: 0
}

.confirm-button.with-input {
    top: 0px;  
    margin-bottom: 7px;    
}